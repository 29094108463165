import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveImages } from "helpers/page";

import ArrowLink from "components/ui/arrowLink/ArrowLink";

import {
    Container,
    Content,
    Title,
    Text,
    ImageWrapper,
    Usps,
    Usp,
    Subtitle,
    StyledButton
} from "./Business.styled";

function Business({ title, description, link, blocks, images }) {
    const { mobileView } = useMediaQueryContext();
    const { mobile, desktop } = resolveImages(
        images,
        {
            url: "/images/lp-business-cards-mobile.jpg",
            alt: "Het perfecte zakelijke geschenk"
        },
        {
            url: "/images/lp-business-cards-desktop.jpg",
            alt: "Het perfecte zakelijke geschenk"
        }
    );

    return (
        <Container forwardedAs="section">
            <Content>
                <Title>{title}</Title>
                <Text>{description}</Text>
                {mobileView && (
                    <ImageWrapper>
                        <Image
                            src={mobile?.url}
                            alt={mobile?.alt}
                            width={525}
                            height={367}
                            quality={75}
                        />
                    </ImageWrapper>
                )}
                {!mobileView && blocks && (
                    <Usps>
                        {blocks.map(({ title, description, link }, index) => (
                            <Usp key={`business-${index}-${title}`}>
                                <Subtitle>{title}</Subtitle>
                                <Text>{description}</Text>
                                <ArrowLink url={link.url} label={link.label} />
                            </Usp>
                        ))}
                    </Usps>
                )}
                <Link href={link?.url} passHref prefetch={false}>
                    <StyledButton
                        element="a"
                        variant={mobileView ? "outline" : "primary"}
                    >
                        {link?.label}
                    </StyledButton>
                </Link>
            </Content>
            {!mobileView && (
                <ImageWrapper>
                    <Image
                        src={desktop?.url}
                        alt={desktop?.alt}
                        width={912}
                        height={557}
                        quality={75}
                    />
                </ImageWrapper>
            )}
        </Container>
    );
}

Business.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    ),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    )
};

Business.defaultProps = {
    title: "Het perfecte zakelijke geschenk",
    description:
        "Geef je relaties of personeel een cultureel avondje of dagje uit met de Nationale Kunst & Cultuur Cadeaukaart. Dé cadeaukaart waarmee kan worden genoten van de mooiste culturele uitstapjes!",
    link: {
        url: "/cultuur-cadeau-bestellen/",
        label: "bestel direct je cadeaukaart"
    },
    blocks: [
        {
            title: "Bestel op factuur",
            description:
                "Voor elke soort bestelling kan je makkelijk bestellen via factuur. Kies in het bestelformulier voor betaalmethode ‘Op factuur’.",
            link: {
                url: "/cultuur-cadeau-bestellen/",
                label: "Direct naar bestellen"
            }
        },
        {
            title: "Goede persoonlijke service",
            description:
                "Op het gebied van zakelijke bestellingen is er vaak veel mogelijk, heb je bijvoorbeeld een goed idee over een verpakking? Let us know!",
            link: {
                url: "/zakelijk/",
                label: "Bekijk de mogelijkheden"
            }
        },
        {
            title: "Cadeauservice",
            description:
                "Geef je vaker een cadeaukaart aan je zakelijke relaties en/of personeel? Maak dan kennis met Cadeauservice!",
            link: {
                url: "/zakelijk/",
                label: "Neem contact op"
            }
        },
        {
            title: "Personalisatie",
            description:
                "Kies voor een speciale verpakking, laat je bedrijfslogo op de kaart drukken en/of laat een boodschap achter. De mogelijkheden zijn eindeloos.",
            link: {
                url: "/zakelijk/",
                label: "Bekijk de mogelijkheden"
            }
        }
    ],
    images: [
        {
            url: "/images/lp-business-cards-mobile.jpg",
            alt: "Het perfecte zakelijke geschenk"
        },
        {
            url: "/images/lp-business-cards-desktop.jpg",
            alt: "Het perfecte zakelijke geschenk"
        }
    ]
};

export default Business;
