import PropTypes from "prop-types";

import {
    Container,
    LinkItems,
    LinkItem,
    Title,
    StyledArrowLink
} from "./Usps.styled";

function Usps({ items }) {
    return (
        <Container forwardedAs="section">
            <LinkItems>
                {items.map(({ title, link }, index) => (
                    <LinkItem key={`usps-${index}-${title}`}>
                        <Title>{title}</Title>
                        {link && (
                            <StyledArrowLink
                                url={link?.url}
                                label={link?.label}
                            />
                        )}
                    </LinkItem>
                ))}
            </LinkItems>
        </Container>
    );
}

Usps.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        })
    )
};

Usps.defaultProps = {
    items: [
        {
            title: "Eindeloos personaliseren",
            link: {
                url: "/cultuur-cadeau-bestellen/",
                label: "Maak jouw cadeau"
            }
        },
        {
            title: "Culturele instellingen in Nederland en België",
            link: {
                url: "/waar-te-besteden/tickets-aan-de-kassa/",
                label: "Bekijk alle acceptanten"
            }
        },
        {
            title: "Gratis verpakt in een mooie cadeau-envelop",
            link: {
                url: "/cultuur-cadeau-bestellen/",
                label: " Of kies een speciale verpakking"
            }
        },
        {
            title: "Waarde van € 5,- tot € 150,-",
            link: {
                url: "/saldocheck/",
                label: "Check jouw saldo"
            }
        }
    ]
};

export default Usps;
