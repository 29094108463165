import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";
import parse from "html-react-parser";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveImages } from "helpers/page";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import {
    Wrapper,
    Container,
    Content,
    Title,
    Subtitle,
    List,
    ListItem,
    Anchor,
    LinkText,
    Text,
    Background
} from "./Usage.styled";

const renderLinks = items => {
    return items.map(({ link }, index) => {
        if (!link) {
            return null;
        }

        return (
            <ListItem key={`${index}}-${link.label}`}>
                <Link href={link.url} passHref prefetch={false}>
                    <Anchor>
                        <LinkText>{link.label}</LinkText>
                        <IconChevronRight size={32} fill="currentColor" />
                    </Anchor>
                </Link>
            </ListItem>
        );
    });
};

function Usage({ title, sections, backgroundImages, isChristmas }) {
    const { mobileView } = useMediaQueryContext();
    const { mobile, desktop } = resolveImages(
        backgroundImages,
        {
            alt: "achtergrond afbeelding",
            url: "/images/banners/banner-usage-mobile.jpg"
        },
        {
            alt: "achtergrond afbeelding",
            url: "/images/banners/banner-usage-desktop.jpg"
        }
    );

    return (
        <Wrapper>
            <Container>
                <Content>
                    <Title>{title}</Title>
                    {sections.map(({ title, description, sections }, index) => (
                        <>
                            <Subtitle as={index === 0 ? "h3" : "h4"}>
                                {title}
                            </Subtitle>
                            {description && <Text>{parse(description)}</Text>}
                            {sections && <List>{renderLinks(sections)}</List>}
                        </>
                    ))}
                </Content>
            </Container>
            <Background isChristmas={isChristmas}>
                <Image
                    src={mobileView ? mobile?.url : desktop?.url}
                    alt={mobileView ? mobile?.alt : desktop?.alt}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="top center"
                    quality={75}
                />
            </Background>
        </Wrapper>
    );
}

Usage.propTypes = {
    title: PropTypes.string,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    link: PropTypes.shape({
                        label: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            )
        })
    ),
    backgroundImages: PropTypes.arrayOf(
        PropTypes.shape({
            alt: PropTypes.string,
            url: PropTypes.string
        })
    ),
    isChristmas: PropTypes.bool
};

Usage.defaultProps = {
    title: "Ontvangen?",
    sections: [
        {
            title: "Heb je een Kunst & Cultuur kaart ontvangen?",
            description:
                "Gefeliciteerd! Je kunt verschillende culturele instellingen door heel Nederland en België bezoeken.",
            sections: [
                {
                    link: {
                        label: "Vind een culturele instelling",
                        url: "/waar-te-besteden/tickets-aan-de-kassa/"
                    }
                },
                {
                    link: {
                        label: "Check je saldo",
                        url: "/saldocheck/"
                    }
                }
            ]
        }
    ],
    backgroundImages: [
        {
            alt: "achtergrond afbeelding",
            url: "/images/banners/banner-usage-mobile.jpg"
        },
        {
            alt: "achtergrond afbeelding",
            url: "/images/banners/banner-usage-desktop.jpg"
        }
    ],
    isChristmas: false
};

export default Usage;
