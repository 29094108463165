import styled from "styled-components";

import media, { sizes } from "constants/media";

export const Container = styled.section`
    position: relative;
    z-index: 1;

    width: 100%;
    margin: 0 0 -160px;
    padding: 0 10px;

    ${media.mdUp} {
        margin: 0;
        padding: 0;
    }
`;

export const UspList = styled.ul`
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 20px 0;
    margin: 0 10px 30px;
    padding: 25px 15px;

    list-style: none;
    background-color: var(--color-white);
    border-radius: var(--border-radius-xs);
    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.05);

    ${media.mobileHide}

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 10px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: calc(100vw - 20px);
        margin: 0 auto -35px;
        padding: 20px;

        box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.1);
    }

    ${media.lgUp} {
        padding: 20px 40px;
    }

    ${media.xlUp} {
        max-width: ${sizes.xl - 20}px;
    }
`;

export const UspItem = styled.li`
    display: flex;
    gap: 0 10px;

    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    strong {
        font-weight: var(--font-weight-bold);
    }

    ${media.mdUp} {
        align-items: flex-start;
    }

    ${media.lgUp} {
        align-items: center;
    }
`;

export const ImageWrapper = styled.div`
    display: inline-flex;
    flex: 0 0 auto;
`;

export const MobileSlider = styled.div`
    ${media.mobileOnly}

    .slider-slide {
        background: var(--color-white);
        border-radius: var(--border-radius-default);
        box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.1);
    }

    .paging-dot {
        fill: var(--color-white);
    }
`;

export const ItemMobile = styled.div`
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    gap: 15px 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px;

    text-align: center;
`;
