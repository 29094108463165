import PropTypes from "prop-types";

import { resolveApiData } from "helpers/page";

import DoubleCard from "components/shared/doubleCard/DoubleCard";

import UseCard from "./useCard/UseCard";

import { Column, Container } from "./Doubler.styled";

function Doubler({ seo, doubler }) {
    return (
        <Container>
            <UseCard
                title={seo?.title ?? undefined}
                description={seo?.description ?? undefined}
            />
            <Column>
                <DoubleCard
                    images={doubler?.images}
                    title={doubler?.title}
                    description={doubler?.description}
                    link={resolveApiData(doubler, "link")}
                />
            </Column>
        </Container>
    );
}

Doubler.propTypes = {
    seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
    }),
    doubler: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.shape({
            url: PropTypes.string,
            label: PropTypes.string
        }),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                label: PropTypes.string
            })
        )
    })
};

Doubler.defaultProps = {
    seo: {
        title: "Je Kunst & Cultuur Cadeaukaart besteden",
        description:
            "Van dorp tot dorp, van stad tot stad. Met de Nationale Kunst & Cultuur Cadeaukaart kan je musea, theaters en monumenten bezoeken door heel Nederland! Omdat de kaart in delen te besteden is, kan je op een dag meerdere activiteiten ondernemen! Wat dacht je van een dagje Maastricht? Bezoek eerst het indrukwekkende en landelijk bekende Bonnefantenmuseum en neem daarna plaats op de eerste rij van het Theater aan het Vrijthof! Of een cultureel dagje Amsterdam? Begin de dag met een bezoek aan het Joods Historisch Museum. Maak vervolgens kennis met de wonderen van het menselijk lichaam in Body Worlds en kom tot rust in de groene oase van de Hortus Botanicus (één van de oudste botanische tuinen van Europa)."
    },
    doubler: {
        title: "Verdubbel de waarde van je kaart",
        description:
            "Maak kans op een verdubbeling van het bedrag op je cadeaukaart. Vul meteen je kaartnummer in en doe mee!"
    }
};

export default Doubler;
