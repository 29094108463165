import PropTypes from "prop-types";

export default PropTypes.shape({
    hero: PropTypes.shape({
        title: PropTypes.string,
        background: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                type: PropTypes.oneOf(["MOBILE", "DESKTOP", "ALL"])
            })
        ),
        image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string
        })
    }),
    seo: PropTypes.shape({
        first: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        }),
        second: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        }),
        third: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        })
    }),
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string
                })
            )
        })
    ),
    usps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.shape({
                label: PropTypes.string,
                url: PropTypes.string
            })
        })
    ),
    received: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            image: PropTypes.arrayOf(
                PropTypes.shape({
                    url: PropTypes.string
                })
            ),
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    sections: PropTypes.arrayOf(
                        PropTypes.shape({
                            title: PropTypes.string,
                            link: PropTypes.shape({
                                label: PropTypes.string,
                                url: PropTypes.string
                            })
                        })
                    )
                })
            )
        })
    ),
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            link: PropTypes.shape({
                url: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            }),
            sections: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    link: PropTypes.shape({
                        label: PropTypes.string,
                        url: PropTypes.string
                    })
                })
            )
        })
    ),
    sections: PropTypes.shape({
        usage: PropTypes.bool,
        business: PropTypes.bool,
        restaurants: PropTypes.bool,
        themesSlider: PropTypes.bool,
        resellers: PropTypes.bool,
        doubler: PropTypes.bool
    })
});
