import PropTypes from "prop-types";

import ClampedText from "components/ui/clampedText/ClampedText";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

import { Section, Container, Title, Content } from "./SeoText.styled";

function SeoText({ title, text, clamp, position, alignment }) {
    return (
        <Section position={position}>
            <Container alignment={alignment}>
                {clamp && <ClampedText title={title} text={text} />}
                {!clamp && (
                    <>
                        <Title>{title}</Title>
                        <Content>
                            <ContentRenderer>{text}</ContentRenderer>
                        </Content>
                    </>
                )}
            </Container>
        </Section>
    );
}

SeoText.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    clamp: PropTypes.bool,
    position: PropTypes.oneOf(["top", "bottom"]),
    alignment: PropTypes.oneOf(["left", "right", "center"])
};

SeoText.defaultProps = {
    clamp: false,
    position: "top",
    alignment: "center"
};

export default SeoText;
