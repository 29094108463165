import styled from "styled-components";
import media from "constants/media";

import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    margin: 0 0 70px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 10px;

        margin: 0 auto 90px;
    }
`;

export const Column = styled.div`
    ${media.mdUp} {
        flex: 1 1 auto;
        width: 50%;
    }
`;
