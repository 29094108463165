import styled from "styled-components";

import media from "constants/media";

import Button from "components/ui/button/Button";
import BaseContainer from "components/ui/container/Container";

export const BackgroundImage = styled.section`
    position: relative;

    display: flex;
    justify-content: center;
    margin: 0 0 170px;
    padding: 60px 0 0;

    background: url(${({ imgMobile }) => imgMobile}) no-repeat center;
    background-size: cover;

    :after {
        position: absolute;
        bottom: -17px;
        left: 0;

        width: 103vw;
        height: 50px;

        background: url("/images/shapes/lp-hero-brush-desktop.svg") center/cover
            no-repeat;

        content: "";
    }

    ${media.mdUp} {
        margin: 0 0 220px;
        padding: 100px 0 0;

        background-image: url(${({ imgDesktop }) => imgDesktop});
    }
`;

export const Container = styled(BaseContainer)`
    margin: 0 0 -140px;
    padding-top: 80px;

    ${media.mdUp} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        margin: 0 auto;
        padding-top: 90px;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 0 60px;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto 55px;

    ${media.mdUp} {
        flex-flow: column nowrap;
        gap: 0;
        justify-content: flex-start;
        max-width: 575px;
        margin: 0 auto 100px;
    }
`;

export const Title = styled.h1`
    max-width: 280px;
    margin: 0 auto 25px;
    padding-top: 0;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);
    text-align: center;

    ${media.mdUp} {
        max-width: 100%;
        margin: 0 0 30px;
    }
`;

export const StyledButton = styled(Button)`
    ${media.mdUp} {
        padding-right: 35px;
        padding-left: 35px;
    }
`;

export const Card = styled.div`
    position: relative;
    z-index: 2;

    width: 100%;
    max-width: 320px;
    margin: 0 auto 30px;

    ${media.mdUp} {
        max-width: 40%;
        margin: 0 auto -150px;
    }

    ${media.lgUp} {
        flex: 1 0 auto;
        max-width: 400px;
    }

    ${media.xlUp} {
        max-width: 444px;
    }
`;
