import PropTypes from "prop-types";
import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import {
    Wrapper,
    Container,
    Title,
    Description,
    Content,
    StyledResellerLogos,
    StyledButton
} from "./SellingPoints.styled";

function SellingPoints({ title, description, link, logos }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Wrapper>
            <Container>
                <Content>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    {mobileView && <StyledResellerLogos logos={logos} />}
                    <Link href={link?.url} passHref prefetch={false}>
                        <StyledButton element="a" variant="secondary">
                            {link?.label}
                        </StyledButton>
                    </Link>
                </Content>
                {!mobileView && <StyledResellerLogos logos={logos} />}
            </Container>
        </Wrapper>
    );
}

SellingPoints.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    )
};

SellingPoints.defaultProps = {
    title: "Verkooppunten",
    description:
        "De Kunst & Cultuur Cadeaukaart kun je ook altijd in de winkel kopen. Klik op de knop en vul je postcode in om winkels bij jou in de buurt te vinden.",
    link: {
        url: "/verkooppunten/",
        label: "vind een winkel in de buurt"
    },
    logos: undefined
};

export default SellingPoints;
