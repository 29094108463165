import PropTypes from "prop-types";
import parse from "html-react-parser";
import Image from "next/image";
import { useInView } from "react-intersection-observer";

import dynamic from "next/dynamic";
import {
    Container,
    ItemMobile,
    MobileSlider,
    UspItem,
    UspList,
    ImageWrapper
} from "./UspBar.styled";

const Slider = dynamic(() => import("components/ui/slider/Slider"));

function UspBar({ items, className }) {
    const { inView, ref } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });

    return (
        <Container className={className}>
            <MobileSlider ref={ref}>
                {inView && (
                    <Slider
                        overflowVisible
                        navigationDesktop={false}
                        navigationMobile={false}
                        items={items?.map(({ title, icon }, index) => (
                            <ItemMobile key={`uspbar-m-${index}-${title}`}>
                                <Image
                                    src={icon?.url}
                                    alt={icon?.alt}
                                    width={40}
                                    height={40}
                                    quality={75}
                                />
                                <span>{parse(title)}</span>
                            </ItemMobile>
                        ))}
                    />
                )}
            </MobileSlider>
            <UspList>
                {items?.map(({ title, icon }, index) => (
                    <UspItem key={`uspbar-d-${index}-${title}`}>
                        <ImageWrapper>
                            <Image
                                src={icon?.url}
                                alt={icon?.alt}
                                width={24}
                                height={24}
                                quality={75}
                            />
                        </ImageWrapper>
                        <span>{parse(title)}</span>
                    </UspItem>
                ))}
            </UspList>
        </Container>
    );
}

UspBar.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            icon: PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired
            })
        })
    ),
    className: PropTypes.string
};

UspBar.defaultProps = {
    items: [
        {
            title: `Voor 17.00 uur besteld = <strong>vandaag verstuurd</strong>`,
            icon: {
                url: "/images/icon-delivery.svg",
                alt: "vandaag verstuurd icon"
            }
        },
        {
            title: `<strong>Gratis</strong> verpakt in mooie cadeau-envelop`,
            icon: {
                url: "/images/icon-mail.svg",
                alt: "verpakking icon"
            }
        },
        {
            title: `<strong>96%</strong> beveelt ons aan <strong>8,8</strong>/10`,
            icon: {
                url: "/images/icon-lock.svg",
                alt: "beoordeling icon"
            }
        }
    ],
    className: ""
};
export default UspBar;
