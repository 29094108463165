import PropTypes from "prop-types";

function IconChevronDown({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            clipRule="evenodd"
            fillRule="evenodd"
            {...rest}
        >
            <path d="m32.1 37.9-19.1-18.9v8.1l19.1 18.9 18.9-18.9v-8.1z" />
        </svg>
    );
}

IconChevronDown.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronDown.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronDown;
