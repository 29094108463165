import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";
import ResellerLogos from "components/shared/resellerLogos/ResellerLogos";

import Button from "components/ui/button/Button";

export const Wrapper = styled.section`
    position: relative;

    margin: 0 0 40px;
    padding: 85px 0 60px;

    background-color: var(--color-primary);

    :before,
    :after {
        position: absolute;
        left: -20px;
        z-index: 1;

        width: 369px;
        height: 112px;

        background: url("/images/shapes/lp-sellingpoints-brush-mobile.svg") top
            left/369px 112px no-repeat;

        content: "";
    }

    :before {
        top: -70px;
    }

    :after {
        bottom: -50px;
    }

    ${media.mdUp} {
        min-height: 464px;
        margin: 0 0 60px;
        padding: 100px 0;

        background: url("/images/shapes/lp-sellingpoints-bg-desktop.svg") top
            center/16383px 464px no-repeat;

        :before,
        :after {
            display: none;
        }
    }
`;

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;
    padding: 0 10px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 20px;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 10px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        max-width: ${columns(5)};
    }
`;

export const Title = styled.h2`
    margin: 0 0 20px;

    color: var(--color-white);
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);

    ${media.mdUp} {
        margin: 0 0 15px;
    }
`;

export const Description = styled(ContentRenderer)`
    margin: 0 0 25px;

    color: var(--color-white);

    ${media.mdUp} {
        margin: 0 0 40px;
    }
`;

export const StyledResellerLogos = styled(ResellerLogos)`
    margin: 0 0 40px;

    ${media.mdUp} {
        width: ${columns(6)};
        margin: 0;
    }
`;

export const StyledButton = styled(Button)`
    align-self: center;

    ${media.mdUp} {
        align-self: flex-start;
    }
`;
