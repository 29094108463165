import styled, { css } from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

export const Section = styled.section`
    text-align: ${({ alignment }) => alignment};

    ${({ position }) =>
        position === "bottom"
            ? css`
                  width: 100%;
                  border-top: 2px solid rgba(0, 0, 0, 0.05);
                  margin: 0 auto 70px;
                  padding: 70px 10px 0;
              `
            : css`
                  margin: 0 auto 55px;
                  padding: 0 20px;
              `};

    ${media.mdUp} {
        ${({ position }) =>
            position === "bottom"
                ? css`
                      width: 100%;
                      margin: 0 auto 100px;
                      padding: 60px 0 0;
                  `
                : css`
                      margin: 0 auto 70px;
                      padding: 0;
                  `}
    }
`;

export const Container = styled.div`
    margin: 0 auto;

    text-align: ${({ alignment }) => alignment};

    ${media.mdUp} {
        max-width: ${columns(8)};
    }
`;

export const Title = styled.h2`
    margin: 0 auto 20px;

    font-size: var(--font-size-h3);
    line-height: var(--line-height-h4);

    ${media.mdUp} {
        font-size: var(--font-size-h2);
        line-height: var(--line-height-h2);
    }
`;

export const Content = styled.div`
    margin: 0 auto;

    color: var(--color-tertiary);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    p {
        margin: 0 0 10px;
    }
`;
