import PropTypes from "prop-types";

export default PropTypes.shape({
    count: PropTypes.number,
    edges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        })
    )
});
