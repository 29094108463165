import styled from "styled-components";

export const Anchor = styled.a`
    display: inline-flex;
    gap: 0 3px;
    align-items: center;

    color: var(--color-secondary);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    svg {
        transition: all var(--animation-duration-default) ease;
    }

    :hover,
    :focus {
        color: var(--color-primary);

        svg {
            transform: translateX(5px);
        }
    }
`;
