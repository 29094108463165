import { useState } from "react";
import PropTypes from "prop-types";

import Button from "components/ui/button/Button";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

import { Title, Text, Controls, Chevron } from "./ClampedText.styled";

function ClampedText({ title, text, linesVisible }) {
    const [visibleLines, setVisibleLines] = useState(linesVisible);
    const [isShowingMore, setIsShowingMore] = useState(false);

    function showMoreText() {
        setIsShowingMore(!isShowingMore);

        if (isShowingMore) {
            setVisibleLines(linesVisible);
        } else {
            setVisibleLines(100);
        }
    }

    return (
        <div>
            {title && <Title>{title}</Title>}
            <Text lines={visibleLines} isShowingMore={isShowingMore}>
                <ContentRenderer>{text}</ContentRenderer>
            </Text>
            <Controls>
                <Button variant="ghost" onClick={showMoreText}>
                    {isShowingMore ? "Lees minder" : "Lees meer"}
                    <Chevron
                        size={19}
                        fill="currentColor"
                        isShowingMore={isShowingMore}
                    />
                </Button>
            </Controls>
        </div>
    );
}

ClampedText.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    linesVisible: PropTypes.number
};

ClampedText.defaultProps = {
    title: "",
    text: "",
    linesVisible: 4
};

export default ClampedText;
