import PropTypes from "prop-types";

import { Item, Logo, Logos } from "./ResellerLogos.styled";

function ResellerLogos({ logos, grayscale, className }) {
    if (logos?.length === 0) {
        return null;
    }

    return (
        <Logos className={className}>
            {logos.map((item, index) => (
                <Item key={`sellingpoints-logo-${index}`}>
                    <Logo
                        src={item.url}
                        alt={item.alt}
                        width={145}
                        height={75}
                        loading="lazy"
                        grayscale={grayscale}
                    />
                </Item>
            ))}
        </Logos>
    );
}

ResellerLogos.propTypes = {
    logos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    grayscale: PropTypes.bool,
    className: PropTypes.string
};

ResellerLogos.defaultProps = {
    logos: [
        {
            url: "/images/logos/sellingpoints/logo-plus-white.svg",
            alt: "Logo Plus"
        },
        {
            url: "/images/logos/sellingpoints/logo-jumbo-white.svg",
            alt: "Logo Jumbo"
        },
        {
            url: "/images/logos/sellingpoints/logo-primera-white.svg",
            alt: "Logo Primera"
        },
        {
            url: "/images/logos/sellingpoints/logo-ako-white.svg",
            alt: "Logo Ako"
        },
        {
            url: "/images/logos/sellingpoints/logo-tabaronde-white.svg",
            alt: "Logo Tabaronde"
        },
        {
            url: "/images/logos/sellingpoints/logo-blz-white.svg",
            alt: "Logo Blz"
        },
        {
            url: "/images/logos/sellingpoints/logo-coop-white.svg",
            alt: "Logo Coop"
        },
        {
            url: "/images/logos/sellingpoints/logo-poiesz-white.svg",
            alt: "Logo Poiesz"
        },
        {
            url: "/images/logos/sellingpoints/logo-cigo-white.svg",
            alt: "Logo Cigo"
        }
    ],
    grayscale: false,
    className: ""
};

export default ResellerLogos;
