import styled from "styled-components";
import Image from "next/image";

import media from "constants/media";

export const Card = styled.div`
    position: relative;

    width: 100%;
    max-width: ${({ width }) => width}px;
    overflow: hidden;
    aspect-ratio: var(--aspect-ratio-card);

    background-color: var(--color-blue-60);
    border-radius: calc(${({ width }) => width}px / 25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    ${media.mdUp} {
        border-radius: calc(${({ width }) => width}px / 20);
    }

    ${media.xlUp} {
        width: ${({ width }) => width}px;
    }
`;

export const CardImage = styled(Image)`
    border-radius: calc(${({ width }) => width}px / 25);
    aspect-ratio: var(--aspect-ratio-card);

    ${media.mdUp} {
        border-radius: calc(${({ width }) => width}px / 20);
    }
`;
