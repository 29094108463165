import styled from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";
import ArrowLink from "components/ui/arrowLink/ArrowLink";

export const Container = styled(BaseContainer)`
    margin: 0 0 40px;

    background: url("/images/lp-usps-background-mobile.webp") calc(100% + 40px)
        0 / 131px 388px no-repeat;

    ${media.mdUp} {
        margin: 0 auto 70px;

        background: none;
    }
`;

export const LinkItems = styled.ul`
    display: flex;
    flex-flow: column wrap;
    gap: 40px 0;
    align-items: flex-start;
    width: 100%;
    max-width: calc(100% - 80px);
    margin: 0;
    padding: 0;

    list-style: none;

    ${media.mdUp} {
        flex-direction: row;
        gap: 20px 0;
        justify-content: space-between;
        max-width: unset;
        margin: 0 -10px;
    }

    ${media.lgUp} {
        gap: 0;
    }
`;

export const LinkItem = styled.li`
    width: 100%;
    padding: 0;

    ${media.mdUp} {
        flex: 1 0 50%;
        padding: 0 20px;
    }

    ${media.lgUp} {
        flex: 1 0 25%;
        padding: 0 10px;
    }
`;

export const Title = styled.h2`
    margin: 0 0 5px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);
`;

export const StyledArrowLink = styled(ArrowLink)`
    display: inline;

    ${media.mdUp} {
        display: inline-flex;
    }
`;
