import styled from "styled-components";

import media from "constants/media";

import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled.div`
    padding: 0 0 20px;

    ${media.mdUp} {
        flex: 1 1 auto;
        width: 50%;
        padding: 0;
    }
`;

export const Title = styled.h2`
    margin: 0 0 15px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h2);
    line-height: var(--font-size-h2);
`;

export const Text = styled(ContentRenderer)`
    margin: 0;

    color: var(--color-tertiary);
`;
