import styled from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

import BaseContainer from "components/ui/container/Container";
import Button from "components/ui/button/Button";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    position: relative;

    margin: 0 auto 90px;

    ${media.mdUp} {
        display: flex;
        margin: 0 auto 70px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    ${media.mdUp} {
        flex: 0 0 auto;
        width: ${columns(8)};
    }

    ${media.lgUp} {
        width: ${columns(6)};
    }
`;

export const Title = styled.h2`
    margin: 0 0 5px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);

    ${media.mdUp} {
        margin: 0 0 10px;

        font-size: var(--font-size-h2);
        line-height: var(--line-height-h2);
    }
`;
export const Text = styled(ContentRenderer)`
    margin: 0 0 10px;

    ${media.mdUp} {
        margin: 0 0 30px;
    }
`;

export const Subtitle = styled.h3`
    margin: 0 0 10px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h5);
    line-height: var(--line-height-h5);

    ${media.mdUp} {
        margin: 0 0 5px;
    }
`;

export const StyledButton = styled(Button)`
    display: block;
    align-self: center;
    margin: 10px auto 0;

    text-align: center;

    ${media.mdUp} {
        display: inline-flex;
        align-self: flex-start;
        margin: 0;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    width: 100%;

    ${media.mdUp} {
        margin-right: -540px;
    }

    ${media.lgUp} {
        margin-right: -240px;
    }
`;

export const Usps = styled.div`
    ${media.mobileHide}

    ${media.mdUp} {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
        margin: 0 0 35px;
    }
`;
export const Usp = styled.div`
    margin-bottom: 25px;

    ${media.smUp} {
        flex: 0 0 50%;
        max-width: calc(50% - 10px);
        margin-bottom: 0;

        ${Text} {
            margin: 0 0 5px;
        }
    }
`;
