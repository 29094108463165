import PropTypes from "prop-types";
import Link from "next/link";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import { Anchor } from "./ArrowLink.styled";

function ArrowLink({ url, label, className }) {
    return (
        <Link href={url} passHref prefetch={false}>
            <Anchor className={className}>
                <span>{label}</span>
                <IconChevronRight
                    fill="currentColor"
                    size={19}
                    aria-hidden="true"
                />
            </Anchor>
        </Link>
    );
}

ArrowLink.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string
};

ArrowLink.defaultProps = {
    className: ""
};

export default ArrowLink;
