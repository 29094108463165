import styled from "styled-components";

import media from "constants/media";

import IconChevronDown from "components/ui/icons/IconChevronDown";

export const Title = styled.h2`
    margin: 0 auto 10px;

    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);

    ${media.mdUp} {
        max-width: 780px;
        margin: 0 auto 20px;
    }
`;

export const Text = styled.div`
    max-height: ${({ lines }) => 20 * lines + 40}px;

    margin: 0 auto;
    overflow: hidden;

    color: var(--color-tertiary);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    transition: all 0.6s ease;

    mask-image: linear-gradient(
        rgba(0, 0, 0, 1) ${({ isShowingMore }) => (isShowingMore ? 99 : 0)}%,
        rgba(0, 0, 0, 0) 100%
    );
    /* stylelint-disable-next-line */
    -webkit-mask-image: -webkit-linear-gradient(
        rgba(0, 0, 0, 1) ${({ isShowingMore }) => (isShowingMore ? 99 : 0)}%,
        rgba(0, 0, 0, 0) 100%
    );

    ${media.mdUp} {
        max-height: ${({ lines }) => 30 * lines}px;
    }
`;

export const Controls = styled.div`
    position: relative;
`;

export const Chevron = styled(IconChevronDown)`
    transform: rotateZ(
        ${({ isShowingMore }) => (isShowingMore ? "-180" : "0")}deg
    );
`;
