import PropTypes from "prop-types";
import Link from "next/link";
import Head from "next/head";

import TiltCard from "components/shared/tiltCard/TiltCard";

import {
    Container,
    BackgroundImage,
    Card,
    Text,
    StyledButton,
    Title
} from "./Hero.styled";

function resolveBackground(images, isChristmas) {
    let mobileImage = "/images/banners/hero-bg-lp-mobile.webp";
    let desktopImage = "/images/banners/hero-bg-lp-desktop.webp";

    if (isChristmas) {
        return {
            mobileImage: "/images/banners/hero-bg-lg-christmas-mobile.webp",
            desktopImage: "/images/banners/hero-bg-lg-christmas-desktop.webp"
        };
    }

    if (images.length === 1) {
        return {
            mobileImage: images[0].url,
            desktopImage: images[0].url
        };
    }

    if (images.length > 1) {
        images.forEach(image => {
            if (image.type === "MOBILE") {
                mobileImage = image.url;
            }
            if (image.type === "DESKTOP") {
                desktopImage = image.url;
            }
            if (image.type === "ALL") {
                mobileImage = image.url;
                desktopImage = image.url;
            }
        });
    }

    return { mobileImage, desktopImage };
}

function Hero({ title, isChristmas, backgroundImages, cardImage }) {
    const { mobileImage, desktopImage } = resolveBackground(
        backgroundImages,
        isChristmas
    );

    return (
        <>
            <Head>
                <link
                    rel="preload"
                    // eslint-disable-next-line react/no-unknown-property
                    fetchpriority="high"
                    as="image"
                    href="/images/shapes/lp-hero-brush-desktop.svg"
                    type="image/svg+xml"
                />
                <link
                    rel="preload"
                    // eslint-disable-next-line react/no-unknown-property
                    fetchpriority="high"
                    as="image"
                    href={mobileImage}
                />
            </Head>
            <BackgroundImage imgMobile={mobileImage} imgDesktop={desktopImage}>
                <Container>
                    <Text>
                        <Title>{title}</Title>
                        <Link
                            href="/cultuur-cadeau-bestellen/"
                            passHref
                            prefetch={false}
                        >
                            <StyledButton element="a" variant="secondary">
                                nu bestellen
                            </StyledButton>
                        </Link>
                    </Text>
                    <Card>
                        <TiltCard
                            widthDesktop={444}
                            image={cardImage?.url}
                            alt={cardImage?.alt}
                            priority
                        />
                    </Card>
                </Container>
            </BackgroundImage>
        </>
    );
}

Hero.propTypes = {
    title: PropTypes.string,
    backgroundImages: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            type: PropTypes.oneOf(["MOBILE", "DESKTOP", "ALL"])
        })
    ),
    cardImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
    }),
    isChristmas: PropTypes.bool
};

Hero.defaultProps = {
    title: "Geef een rijke ervaring als cadeau.",
    backgroundImages: [
        {
            url: "/images/banners/hero-bg-lp-desktop.webp",
            type: "DESKTOP"
        },
        {
            url: "/images/banners/hero-bg-lp-mobile.webp",
            type: "MOBILE"
        }
    ],
    cardImage: {
        url: "/images/card.webp",
        alt: "Nationale Kunst & Cultuur cadeaukaart"
    },
    isChristmas: false
};

export default Hero;
