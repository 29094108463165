import styled, { css } from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

import BaseContainer from "components/ui/container/Container";

const determineParticleBackground = isChristmas => {
    if (isChristmas) {
        return css`
            width: 1440px;
            height: 560px;

            background: url("/images/shapes/lp-usage-particles-christmas-desktop.svg")
                center/auto no-repeat;
        `;
    }

    return css`
        width: 1426px;
        height: 566px;

        background: url("/images/shapes/lp-usage-particles-desktop.svg")
            center/auto no-repeat;
    `;
};

export const Wrapper = styled.section`
    position: relative;

    margin: 0 0 40px;
    padding: 310px 0 100px;

    :before {
        position: absolute;
        top: -125px;
        left: -10px;

        transform: rotateZ(-25deg);

        content: "";

        ${({ isChristmas }) => determineParticleBackground(isChristmas)}
    }

    ${media.mdUp} {
        min-height: 560px;
        margin: 0 0 65px;
        padding: 70px 15px;

        :before {
            display: none;
        }
    }

    ${media.lgUp} {
        padding: 105px 0 115px;
    }
`;

export const Container = styled(BaseContainer)`
    ${media.mdUp} {
        display: flex;
        justify-content: flex-end;
    }

    ${media.lgUp} {
        padding-right: calc(${columns(1)} + 10px + var(--gutter));
    }
`;

export const Content = styled.div`
    color: var(--color-white);

    ${media.mdUp} {
        max-width: ${columns(6)};
    }

    ${media.lgUp} {
        max-width: ${columns(4)};
    }
`;

export const Title = styled.h2`
    margin: 0;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);

    ${media.mobileHide}
`;

export const Subtitle = styled.h3`
    max-width: 260px;
    margin: 0 0 15px;
    padding: 0;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-${({ as }) => (as === "h3" ? "h5" : "h6")});
    line-height: var(--line-height-body);

    ${media.mdUp} {
        max-width: unset;
    }
`;

export const Text = styled.p`
    max-width: 260px;
    margin: 0 0 20px;

    color: var(--color-white);

    ${media.mdUp} {
        max-width: unset;
    }
`;

export const List = styled.ul`
    margin: 0 0 20px;
    padding: 0;

    list-style: none;

    &:last-of-type {
        margin: 0;
    }
`;

export const ListItem = styled.li`
    position: relative;

    display: flex;
    width: 100%;

    border-top: 1px solid rgba(255, 255, 255, 0.1);

    :last-of-type {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    ${media.mdUp} {
        border-top: 0;

        & + & {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }

        :last-of-type {
            border-bottom: 0;
        }
    }
`;

export const Anchor = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 8px 0;

    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-body);
    line-height: var(--line-height-body);

    svg {
        transition: all var(--animation-duration-default) ease;
    }

    @media (any-hover: hover) {
        :hover,
        :focus {
            svg {
                transform: translateX(5px);
            }
        }
    }
`;

export const LinkText = styled.span`
    flex: 0 1 100%;
`;

export const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: var(--color-secondary);

    /* stylelint-disable-next-line */
    :before,
    :after {
        position: absolute;
        left: -60px;
        z-index: 1;

        width: 427px;
        height: 59px;

        background: url("/images/shapes/lp-usage-brush-mobile.svg") top center
            no-repeat;

        content: "";
    }

    /* stylelint-disable-next-line */
    :before {
        top: -30px;
    }

    :after {
        bottom: -15px;
    }

    /* stylelint-disable-next-line */
    ${media.mdUp} {
        :before,
        :after {
            position: absolute;
            left: 50%;
            z-index: 1;

            transform: translateX(-50%);

            content: "";
        }

        :before {
            top: -50px;

            width: 1440px;
            height: 84px;

            background: url("/images/shapes/lp-usage-brush-desktop.svg") top
                center no-repeat;
        }

        :after {
            top: -15px;
            ${({ isChristmas }) => determineParticleBackground(isChristmas)}
        }
    }
`;
