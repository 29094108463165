import PropTypes from "prop-types";

import landingPagePropTypes from "types/LandingPage.propTypes";
import { EventSliderItemApiPropTypes } from "types/EventSliderItem.propTypes";
import themesPropTypes from "types/Themes.propTypes";
import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveApiData, resolveUspsWithIcon } from "helpers/page";

import SeoText from "components/shared/seoText/SeoText";
import UspBar from "components/shared/uspBar/UspBar";
import ThemeSlider from "components/shared/themeSlider/ThemeSlider";
import MerchantsAndEvents from "components/shared/merchantsAndEvents/MerchantsAndEvents";

import Hero from "./hero/Hero";
import Steps from "./steps/Steps";
import Usps from "./usps/Usps";
import Usage from "./usage/Usage";
import Business from "./business/Business";
import Doubler from "./doubler/Doubler";
import SellingPoints from "./sellingPoints/SellingPoints";

function resolveBlockData(block) {
    return block && block.length > 0 ? block[0] : undefined;
}

function LandingPage({ page, themes, events, isChristmas }) {
    const { mobileView } = useMediaQueryContext();
    const { hero, seo, steps, usps, received, sections, blocks } = page || {};
    const { first, second, third } = seo || {};

    const stepsContent = resolveBlockData(steps);
    const usageContent = resolveBlockData(received);
    const eventsContent = blocks?.[0];
    const businessContent = blocks?.[1];
    const themesSliderContent = blocks?.[2];
    const resellersContent = blocks?.[3];
    const doublerContent = blocks?.[4];

    return (
        <>
            <Hero
                title={hero?.title}
                cardImage={hero?.image}
                backgroundImages={hero?.background}
                isChristmas={isChristmas}
            />
            {first &&
                resolveApiData(first, "title") &&
                resolveApiData(first, "description") && (
                    <SeoText
                        title={first?.title}
                        text={first?.description}
                        alignment={mobileView ? "left" : "center"}
                        clamp
                    />
                )}
            <Steps title={stepsContent?.title} items={stepsContent?.sections} />
            <Usps items={usps} />
            {sections.usage && (
                <>
                    <UspBar items={resolveUspsWithIcon(hero?.usps)} />
                    <Usage
                        title={usageContent?.title}
                        sections={usageContent?.sections}
                        backgroundImages={usageContent?.images}
                        isChristmas={isChristmas}
                    />
                </>
            )}
            {sections.restaurants && (
                <MerchantsAndEvents
                    title={eventsContent?.title}
                    description={eventsContent?.description}
                    link={resolveApiData(eventsContent, "link")}
                    eventsTitle={resolveApiData(
                        eventsContent?.sections?.[0],
                        "title"
                    )}
                    events={events?.edges}
                    isChristmas={isChristmas}
                />
            )}
            {mobileView && sections.themesSlider && (
                <ThemeSlider
                    title={themesSliderContent?.title}
                    themes={themes?.edges}
                />
            )}
            {sections.business && (
                <Business
                    title={businessContent?.title}
                    description={businessContent?.description}
                    link={resolveApiData(businessContent, "link")}
                    blocks={businessContent?.sections}
                    images={businessContent?.images}
                />
            )}
            {!mobileView && sections.themesSlider && (
                <ThemeSlider
                    title={themesSliderContent?.title}
                    themes={themes?.edges}
                />
            )}
            {sections.resellers && (
                <SellingPoints
                    title={resellersContent?.title}
                    description={resellersContent?.description}
                    link={resolveApiData(resellersContent, "link")}
                    logos={resellersContent?.images}
                />
            )}
            {sections.doubler && (
                <Doubler seo={second} doubler={doublerContent} />
            )}
            {third &&
                resolveApiData(third, "title") &&
                resolveApiData(third, "description") && (
                    <SeoText
                        title={third?.title}
                        text={third?.description}
                        position="bottom"
                        alignment="left"
                        clamp={mobileView}
                    />
                )}
        </>
    );
}

LandingPage.propTypes = {
    page: landingPagePropTypes,
    events: PropTypes.shape({
        edges: PropTypes.arrayOf(EventSliderItemApiPropTypes)
    }),
    themes: themesPropTypes,
    isChristmas: PropTypes.bool
};

LandingPage.defaultProps = {
    page: {
        hero: {
            title: "Geef een rijke ervaring als cadeau"
        },
        seo: {},
        steps: [],
        received: [],
        blocks: [],
        sections: {
            usage: true,
            business: true,
            restaurants: true,
            themesSlider: true,
            resellers: true,
            doubler: true
        }
    },
    events: undefined,
    themes: undefined,
    isChristmas: process.env.featureFlags.christmas
};

export default LandingPage;
